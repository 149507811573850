import { gql } from '@/__generated__';

export const StreamPostCardFragment = gql(`
fragment StreamPostCardFragment on StreamPost {
  ...BaseStreamPostCardFragment
  author {
    ...PostCardAuthorFragment
    __typename
  }
  stream {
    id
    label
    isEmpty
    paidStreamData {
      price
    }
    __typename
  }
  voted
  __typename
}
`);
