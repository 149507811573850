import { LayoutCard } from '../layout/card.tsx';
import { FC, forwardRef, PropsWithChildren, ReactNode } from 'react';
import {
  highlightedSection,
  highlightedSectionHeader,
  listContainer,
  sectionLabel,
} from './highlighted-feed-wrapper.css.ts';

interface HighlightedFeedWrapperProps extends PropsWithChildren {
  label: string;
  action?: ReactNode;
  empty?: {
    state: ReactNode;
    isEmpty: boolean;
  };
}

export const HighlightedFeedWrapper: FC<HighlightedFeedWrapperProps> = ({
  label,
  action,
  empty,
  children,
}) => {
  return (
    <LayoutCard
      className={highlightedSection}
      data-empty={empty?.isEmpty ? '' : undefined}
    >
      <div className={highlightedSectionHeader}>
        <h3 className={sectionLabel}>{label}</h3>
        {action}
      </div>
      {children}
      {empty && empty.isEmpty ? empty.state : null}
    </LayoutCard>
  );
};

export const List = forwardRef<HTMLDivElement>(function ListComponent(
  { ...rest },
  ref,
) {
  return (
    <LayoutCard ref={ref} className={listContainer} {...rest}></LayoutCard>
  );
});
